<script>
import api from '@/command/api'
import PopButton from '@/components/PopButton'
import apiTool from '@/command/apiTool'
import { getAction, postAction, putAction } from '@/command/netTool'
import Naves from '@/views/com/Naves'
import TemplateHeaderTable from '@/components/TemplateHeaderTable'
import moment from 'moment'
import { Card, Divider } from 'ant-design-vue'
import { orderStateList, orderSourceList, regSourceList } from '@/utils/textFile'
export default {
  name: 'userManagementDetail',
  data() {
    return {
      ...api.command.getState(),
      activeKey: 0,
      detail: {},
      width: '',
      infoCard1: [],
      infoCard2: [],
    }
  },
  mounted() {
    let sider = this.getSider()
    this.width = sider.clientWidth
    window.addEventListener('resize', () => {
      setTimeout(() => {
        this.width = sider.clientWidth
      }, 200)
    })
    const { id } = this.$route.query
    if (id) {
      this.getInit(id)
      api.command.getDetail
        .call(this, {
          url: `/farmUser/detail/${id}`,
        })
        .then((res) => {
          this.detail = {
            ...res,
            // realNameInput: res.realNameInput ? res.realNameInput.split(',') : [],
          }
          console.log('res', res)
          this.infoCard1 = [
            {
              title: '会员信息',
              itemList: [
                {
                  name: '会员等级',
                  value: 'Lv' + res.level,
                },
                {
                  name: '会员积分',
                  value: res.integral,
                },
              ],
            },
          ]
          this.infoCard2 = [
            {
              title: '最近消费',
              itemList: [
                {
                  name: '最近消费金额',
                  value: res.lastBuyAmount,
                },
                {
                  name: '最近消费时间',
                  value: res.lastBuyTime,
                },
              ],
            },
            {
              title: '累计消费',
              itemList: [
                {
                  name: '累计消费金额',
                  value: res.totalBuyAmount,
                },
                {
                  name: '累计实付金额',
                  value: res.totalPayAmount,
                },
                {
                  name: '累计消费次数',
                  value: res.totalBuyCount,
                },
                {
                  name: '累计购买商品数',
                  value: res.totalBuyProductCount,
                },
              ],
            },
            {
              title: '累计售后',
              itemList: [
                {
                  name: '累计售后金额',
                  value: res.returnAmount,
                },
                {
                  name: '累计售后次数',
                  value: res.returnCount,
                },
              ],
            },
          ]
          this.$forceUpdate()
        })
    }
  },
  methods: {
    getSider() {
      return document.getElementsByClassName('ant-layout-sider-children')[0]
    },
    getInit(id) {
      api.command.getList.call(this, {
        url: '/farmUser/queryOrderList',
        current: 1,
        paramsValue: {
          userId: id,
        },
      })
    },
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '名称(76字符内)',
            type: 'input',
            cols: 12,
            key: 'productName',
            props: {
              maxLength: 76,
            },
            rules: [
              {
                required: true,
              },
            ],
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '返回',
          type: 'default',
          onClick: () => this.$router.back(),
          display: true,
        },
      ].filter((e) => e.display)
      return {
        left,
      }
    },
    renderTab() {
      if (this.activeKey === 0) {
        return [this.getForm1()]
      } else if (this.activeKey === 1) {
        return []
      }
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '订单编号',
          align: 'left',
          width: '16.6%',
        },
        {
          dataIndex: 'content',
          title: '商品名称',
          align: 'left',
          width: '16.6%',
          customRender: (text, records) => {
            return (
              <div>
                {records.orderItemList.map((e) => (
                  <div class="product-order-item">
                    <img src={e.productPic} />
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          width: '16.6%',
        },
        {
          dataIndex: 'status',
          title: '订单状态',
          align: 'left',
          width: '16.6%',
          customRender: (text) => (orderStateList.find((e) => e.value == text) || { text: '' }).text,
          filterMultiple: true,
          filters: orderStateList,
        },
        {
          dataIndex: 'fromType',
          title: '订单来源',
          align: 'left',
          width: '16.6%',
          customRender: (text) => (orderSourceList.find((e) => e.value == text) || { text: '' }).text,
          filters: orderSourceList,
          filterMultiple: false,
          onExport: (text) => (orderSourceList.find((e) => e.value == text) || { text: '' }).text,
        },
        {
          dataIndex: 'paymentTime',
          title: '支付时间',
          align: 'left',
          width: '16.6%',
        },
      ]
    },
    renderInfoCard(data = []) {
      return (
        <div class="infoBox">
          {data.map((e) => {
            return (
              <div class="infoCard">
                <div class="infoCard_title">{e.title}</div>
                {e.itemList.map((item) => {
                  return (
                    <div class="infoCard_item">
                      <span>{item.name}: </span>
                      <span style={{ marginLeft: '6px' }}>{item.value}</span>
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>
      )
    },
  },
  render() {
    let basicInfo = [
      {
        display: this.detail.telephone,
        name: '手机号',
        value: this.detail.telephone,
      },
      {
        display: this.detail.createDate,
        name: '注册时间',
        value: this.detail.createDate,
      },
      {
        display: this.detail.regSource,
        name: '来源渠道',
        value: this.detail.regSource && regSourceList[this.detail.regSource].text,
      },
      {
        display: this.detail.status == '1' && this.detail.updateDate,
        name: '注销时间',
        value: this.detail.updateDate,
      },
    ].filter((e) => e.display)
    return (
      <div class="detail-form-group">
        <Naves
          navData={['用户概况', '用户订单']}
          onActive={(index) => (this.activeKey = index)}
          activeCurrentKey={this.activeKey}
        />
        {this.activeKey == 0 && (
          <div class="userBox">
            <Card title="基本信息" style={{ marginTop: '10px' }}>
              <div class="userInfo">
                <img src={this.detail.headImg} />
                <div class="userInfo_right">
                  <div class="userInfo_right_title">{this.detail.name}</div>
                  <div class="userInfo_right_value">
                    {basicInfo.map((e, i) => {
                      return (
                        <span>
                          <span>
                            {e.name}: {e.value}
                          </span>
                          {basicInfo.length !== i + 1 && (
                            <Divider
                              style={{
                                margin: '0 12px',
                              }}
                              type="vertical"
                            />
                          )}
                        </span>
                      )
                    })}
                  </div>
                </div>
              </div>
            </Card>
            <Card title="会员信息" style={{ marginTop: '10px' }}>
              {this.renderInfoCard(this.infoCard1)}
            </Card>
            <Card title="消费分析" style={{ marginTop: '10px' }}>
              {this.renderInfoCard(this.infoCard2)}
            </Card>
          </div>
        )}
        {this.activeKey == 1 && (
          <TemplateHeaderTable
            showRowSelect={false}
            tableColumns={this.getColumns()}
            records={this.records}
            tableStyle={{ marginTop: '-10px' }}
          />
        )}
        <div class="detail-form-foot" style={{ left: this.width + 'px' }}>
          <div>
            <PopButton
              item={{
                name: '返回',
              }}
              onClick={() => this.$router.back()}
            />
          </div>
        </div>
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-order-item:nth-child(1)::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  color: #999999;
}
/deep/ .detail-form-group {
  height: calc(100vh - 230px);
  display: flex;
  flex: 1;
  flex-direction: column;

  //*去除详情滚动
  overflow-x: hidden;
  overflow-y: auto;
  height: 78vh;

  @media screen and (min-width: 2560px) {
    height: 88vh;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}
/deep/.userBox {
  max-height: calc(100vh - 228px);
  overflow: auto;
  padding-bottom: 10px;
}
/deep/.template-card {
  min-height: calc(100vh - 234px);
  max-height: calc(100vh - 234px);
}
/deep/ .detail-form-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  background: white;
  height: 64px;
  border: 1px solid rgba(220, 222, 226, 1);
  padding-left: 25px;
  padding-right: 25px;
  position: fixed;
  right: 0px;
  bottom: 0px;
  z-index: 999;
}
.userInfo {
  display: flex;
  img {
    width: 70px;
    height: 70px;
    border-radius: 12px;
    margin-right: 12px;
  }
  .userInfo_right {
    .userInfo_right_title {
      font-size: 16px;
      font-weight: 700;
      margin: 8px 0px;
    }
    .userInfo_right_value {
      font-size: 14px;
      font-weight: 400;
    }
  }
}
.infoBox {
  display: flex;
  .infoCard {
    background-color: #f4f6f8;
    border-radius: 4px;
    min-height: 140px;
    width: 25%;
    font-size: 14px;
    font-weight: 400;
    padding: 6px 14px;
    margin-right: 20px;
    .infoCard_title {
      font-size: 16px;
      font-weight: 700;
      margin: 6px 0px;
    }
    .infoCard_item {
      margin: 4px 0px;
    }
  }
}
</style>
